import { tracking } from 'helper/eventSegment';

export default {
    CANDIDATE_HIRED: (data) => {
        tracking('G-candidate-hired', data);
    },
    CANDIDATE_VIEWED: (data) => {
        tracking('G-candidate-viewed', data);
    },
    QUESTION_SCORED: (data) => {
        tracking('G-question-scored', data);
    },
    QUESTION_SCORED_IN_GRADING_MODEL: (data) => {
        tracking('G-question-scored', data);
    },
    COMMENT_POSTED: (data) => {
        tracking('G-comment-posted', data);
    },
    CANDIDATE_PROGRESSED: (data) => {
        tracking('G-candidate-progressed', data);
    },
    MESSAGE_SEND: (data) => {
        tracking('G-message-sent', data);
    },
    DEADLINE_EXTENDED: (data) => {
        tracking('G-deadline-extended', data);
    },
    CANDIDATE_SHARED: (data) => {
        tracking('G-candidate-shared', data);
    },
    CANDIDATE_REJECTED: (data) => {
        tracking('G-candidate-rejected', data);
    },
    CANDIDATE_UNREJECTED: (data) => {
        tracking('G-candidate-unrejected', data);
    },
    TT_PREVIEWED: (data) => {
        tracking('G-tt-previewed', data);
    },
    TB_INTENT: (data) => {
        tracking('G-tb-intent', data);
    },
    CANDIDATE_TAGGED: (data) => {
        tracking('G-candidate-tagged', data);
    },
    CANDIDATE_UNTAGGED: (data) => {
        tracking('G-candidate-untagged', data);
    },
    CANDIDATE_SPREADSHEET_TEST_CASES_RESULT_CHANGED: (data) => {
        tracking('G-candidate-spreadsheet-test-cases-result-changed', data);
    }
};
