import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import {
    loadAssessmentStats,
    getNewExtensionsRequests
} from 'requests/AssessmentRequests';
import { getAuditionAiHealthInfo } from 'requests/ActiveLearningRequests';
import { AI_LIGHT_OPTIMIZED, MANUAL_GRADED } from 'helper/constants';
import { assessmentSettingsStore } from './store';

const getAverageScore = (averageScore, totalCount) => {
    if (!totalCount || averageScore === null) {
        return ({
            value: '-',
            label: 'Average score'
        });
    }
    return ({
        value: `${averageScore}%`,
        label: 'Average score'
    });
};

const checkActiveStatus = status => ![MANUAL_GRADED, AI_LIGHT_OPTIMIZED].includes(status);


export const returnStatsArray = (data) => {
    const {
        completionsRate, averageScore,
        inProgressRate, statsCount = {},
        expiredRate, countRealCandidates,
        avgTimeSpent, archivedCount
    } = data;

    return [{
        value: countRealCandidates,
        label: 'Candidates'
    }, {
        value: statsCount.inProgress,
        percentage: inProgressRate,
        label: 'In progress'
    }, {
        value: statsCount.expired,
        percentage: expiredRate,
        label: 'Expired'
    }, {
        value: statsCount.completed,
        percentage: completionsRate,
        label: 'Completed'
    }, {
        value: `${Math.round(avgTimeSpent / 60)}min`,
        label: 'Avg Completion Time'
    },
    getAverageScore(averageScore, statsCount.completed), {
        value: statsCount.hired,
        label: 'Hired'
    }, {
        value: archivedCount,
        label: 'Arhived'
    }
    ];
};

class StatsStore {
    @observable loading = true;

    @observable totalCount = null;

    @observable completedNotArchivedCount = null;

    @observable archivedCount = null;

    @observable shortlistCandidatesCount = null;

    @observable statsArr = [];

    @observable requestedMoreTimeCount = null;

    @observable resetCount = null;

    @observable slug = '';

    @observable isNoCandidates = false;

    @observable activeLearningStatistic = null;

    @observable selectedQuestionIndex = 0

    @observable tagsCount = {};

    @observable statsCount = {};

    @observable extensionRequestedCandidates = [];

    @action loadExtensionsRequests = () => {
        if (!this.slug) return;
        getNewExtensionsRequests(this.slug)
            .then(({ success, data }) => {
                if (success) {
                    this.extensionRequestedCandidates = data;
                }
            })
            .finally(() => {
                this.isLoadingLocal = false;
            });
    };


    @action loadActiveLearning = (init = false) => {
        if (!this.slug) return;
        return getAuditionAiHealthInfo(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.activeLearningStatistic = data;
                    if (init) {
                        this.selectedQuestionIndex = 0;
                        const { questionList } = this.activeLearningStatistic;
                        const { status: selectedQuestionStatus } = questionList[0];
                        if (!checkActiveStatus(selectedQuestionStatus)) {
                            this.findNextQuestionToGrade();
                        }
                    }
                }
            });
    };

    @action findNextQuestionToGrade = () => {
        if (this.activeLearningStatistic) {
            const { questionList } = this.activeLearningStatistic;
            const startIndex = this.selectedQuestionIndex + 1 !== questionList.length ? this.selectedQuestionIndex + 1 : 0;
            for (let i = startIndex; i < questionList.length; i++) {
                const nextQuestion = questionList[i];
                const { status: nextQuestionStatus } = nextQuestion;
                if (checkActiveStatus(nextQuestionStatus)) {
                    this.setSelectedQuestionIndex(i);
                    break;
                }
            }
        }
    };

    @action setSelectedQuestionIndex = (index) => {
        this.selectedQuestionIndex = index;
    }

    @action loadAssessmentStats = () => {
        this.loadFullStats();
        if (assessmentSettingsStore.audition?.active) this.loadActiveLearning();
    };

    @action loadFullStats = () => {
        if (!this.slug) return;
        return loadAssessmentStats(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.setStatsData(data);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action setExtensionRequestedCandidates = (data) => {
        this.extensionRequestedCandidates = data;
    }

    @action setLoading = (value) => {
        this.loading = value;
    };

    @action setSlug = (value) => {
        this.slug = value;
    };

    @action increaseShortlistCandidatesCount = (increase = true) => {
        if (increase) { this.shortlistCandidatesCount += 1; } else {
            this.shortlistCandidatesCount -= 1;
        }
    };

    @action setStatsData = (data) => {
        const {
            totalCandidatesCount, totalArchivedCount,
            shortlistCandidatesCount,
            completionsLeft, limitOfCompletions,
            statsCount = {}, tagsCount = {},
            resetCount, requestedMoreTimeCount,
            totalCompletionsCount
        } = data;

        this.totalCount = totalCandidatesCount - totalArchivedCount;
        this.shortlistCandidatesCount = shortlistCandidatesCount;
        this.statsArr = returnStatsArray(data);
        this.isNoCandidates = !totalCandidatesCount;
        this.completionsLeft = completionsLeft;
        this.limitOfCompletions = limitOfCompletions;
        this.resetCount = resetCount;
        this.requestedMoreTimeCount = requestedMoreTimeCount;
        this.statsCount = statsCount;
        this.tagsCount = tagsCount;
        this.completedNotArchivedCount = totalCompletionsCount - totalArchivedCount;
        this.archivedCount = totalArchivedCount;
    };

    @action cleanStatsData = () => {
        this.loading = false;
        this.totalCount = null;
        this.shortlistCandidatesCount = null;
        this.statsArr = [];
        this.slug = '';
        this.isNoCandidates = false;
        this.activeLearningStatistic = null;
        this.statsCount = {};
        this.tagsCount = {};
        this.requestedMoreTimeCount = null;
        this.completedNotArchivedCount = null;
        this.archivedCount = null;
        this.extensionRequestedCandidates = [];
    }

    @computed get isActiveLearningDialogShouldBeClosed() {
        if (this.activeLearningStatistic?.questionList) {
            const newList = this.activeLearningStatistic?.questionList.filter(
                question => checkActiveStatus(question.status)
            );
            return (newList.length - 1) === 0;
        }
        return false;
    }
}

export const statsStore = new StatsStore();
export const statsCtx = createContext(statsStore);
