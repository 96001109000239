import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import { Link, Redirect, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import event from 'events/AuthEvents';
import Button from '@mui/material/Button';
import { loginUser } from 'requests/LoginRequests';
import IEBanner from 'components/layout/IEBanner';
import Loader from 'components/loaders/Loader';
import SsoLoginDialog from 'components/dialogs/SsoLoginDialog';
import { validateEmail, getBaseUrlForRedirect } from 'helper/commonFunctions';
import HomepageWrapper from '../common/HomepageWrapper';
import Lock from './lock.svg';

import styles from '../common/styles';

const Login = (props) => {
    const [ssoDialogOpen, setSsoDialogOpen] = useState(false);
    const [form, setForm] = useState({});
    const [errorForm, setErrorForm] = useState({});
    const [activeLoaderButton, setActiveLoaderButton] = useState(false);

    const { match, flashMessage, location, history, classes } = props;
    const isPremium = location.state?.isPremium;

    useEffect(() => {
        event.VIEWED_PAGE();
        const link = document.createElement('link');
        link.id = 'canonical';
        link.rel = 'canonical';
        link.href = 'https://app.vervoe.com/login';
        document.head.append(link);

        const { wrong_user_type } = match.params;
        if (wrong_user_type) {
            let message = '';
            if (wrong_user_type === '2') {
                message = "An employer's account is already registered with that email address";
            } else {
                message = "An author's account is already registered with that email address";
            }
            flashMessage(message);
        }

        return () => {
            document.getElementById('canonical').remove();
        };
    }, []);

    const renderError = (formParam) => {
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    const renderValue = (value) => {
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    const validateForm = () => {
        const { email, password } = form;
        const currentErrors = {};
        if (!email) {
            currentErrors.email = 'Please enter your email.';
        } else if (!validateEmail(email)) {
            currentErrors.email = 'Please enter a valid email address.';
        }
        if (!password) {
            currentErrors.password = 'Please enter your password.';
        }
        if (Object.keys(currentErrors).length) {
            setErrorForm(currentErrors);
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const updateForm = { ...form };
        const updateErrorForm = { ...errorForm };
        updateForm[targetName] = targetValue;
        updateErrorForm[targetName] = '';
        setForm(updateForm);
        setErrorForm(updateErrorForm);
    };

    const login = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        const parsed = qs.parse(location.search);
        const { redirect: redirectUrl } = parsed;
        setActiveLoaderButton(true);
        loginUser(JSON.stringify(form), 'redeem' in parsed)
            .then((data) => {
                if (data.mfaToken) {
                    history.push('/mfa-code-verification', {
                        enteredEmail: form.email,
                        mfaToken: data.mfaToken,
                        isPremium,
                        emailConfirmationExpiresAfterMinutes: data.emailConfirmationExpiresAfterMinutes
                    });
                }
                if (data.success) {
                    event.IDENTIFY(data.uuid, data.userSegmentTraits);
                    event.USER_LOGIN({ email: form.email });
                    localStorage.setItem('wasAuthorized', 1);
                    const baseRedirectUrl = redirectUrl ? `${redirectUrl}${redirectUrl.includes('?') ? '' : '?'}` : '/welcome?';
                    const isPremiumParam = isPremium ? '&isPremium=true' : '';
                    window.location = `${getBaseUrlForRedirect(data.companySlug)}${baseRedirectUrl}&token=${data.refreshToken}${isPremiumParam}`;
                }
            })
            .catch((err) => {
                setActiveLoaderButton(false);
                if (err.response && err.response.status === 418) {
                    const { emailConfirmationExpiresAfterMinutes } = err.response.data;
                    history.push('/code-verification', { enteredEmail: form.email, redirectUrl, emailConfirmationExpiresAfterMinutes });
                } else if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            });
    };

    const renderLoginPage = () => {
        const parsed = qs.parse(location.search);

        return (
            <HomepageWrapper>
                <h1 className={classes.heading}>Sign in</h1>
                <form onSubmit={login} id="loginForm">
                    <TextInput
                        variant="outlined"
                        label="Enter your email"
                        isError={Boolean(renderError('email'))}
                        helperText={renderError('email')}
                        value={renderValue('email')}
                        fullWidth
                        type="text"
                        name="email"
                        onChange={handleChange}
                    />
                    <TextInput
                        variant="outlined"
                        label="Enter your password"
                        isError={Boolean(renderError('password'))}
                        helperText={renderError('password')}
                        value={renderValue('password')}
                        fullWidth
                        type="password"
                        name="password"
                        onChange={handleChange}
                    />
                    <p className={classes.forgotPW}>
                        <Link to="/reset-password" className={classes.link}>
                            Forgot your password?
                        </Link>
                    </p>
                    <div style={{ position: 'relative' }}>
                        <Button
                            className={classes.signInButton}
                            variant="contained"
                            color="primary"
                            fullWidth
                            id="btn_login"
                            type="submit"
                            disabled={activeLoaderButton}
                        >
                            Sign in to Vervoe
                        </Button>
                        {
                            activeLoaderButton && (
                                <Loader
                                    className={classes.loader}
                                    size={24}
                                    show
                                    thickness={2}
                                />
                            )
                        }
                    </div>
                </form>
                <div className="u-dsp--f u-jc--sb u-ai--center u-mrg--tx2">
                    <div role="presentation" className={classes.sso} onClick={() => setSsoDialogOpen(true)}>
                        <img src={Lock} alt="+" className="u-mrg--rx1" /> Sign in with SSO
                    </div>
                    <div className={classes.signUp}>
                        Don't have an account?&nbsp;
                        <Link to={{ pathname: `/registration${'redeem' in parsed ? '?redeem' : ''}`, state: { isPremium } }} className={classes.link}>
                            Sign up for free!
                        </Link>
                    </div>
                </div>
                <SsoLoginDialog
                    open={ssoDialogOpen}
                    onClose={() => setSsoDialogOpen(false)}
                />
            </HomepageWrapper>
        );
    };

    const isIE = !!document.documentMode;

    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
        return <Redirect to="/welcome" />;
    }

    return (
        <div>
            {
                (isIE)
                    ? <IEBanner />
                    : renderLoginPage()
            }
        </div>
    );
};

export default withStyles(styles)(withRouter(Login));
