import { observer } from 'mobx-react-lite';
import React, { createContext, useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import InviteForm from 'components/job_or_assessment_settings/InviteForm';
import ImportCSVForm from 'components/job_or_assessment_settings/InviteForm/ImportCSVForm';
import CircularProgress from '@mui/material/CircularProgress';
import { checkPluralNew } from 'helper/commonFunctions';
import { InviteCandidatesTabStore } from 'components/job_or_assessment_settings/InviteForm/store';
import InviteResultsDialog from 'components/job_or_assessment_settings/InviteResultsDialog';
import AddNewTagsDialog from 'components/dialogs/AddNewTagsDialog';
import TestToMyselfButton from '../TestToMyselfButton';
import { statsCtx } from '../../../statsStore';
import { assessmentSettingsCtx } from '../../../store';
import styles from './styles';

export const inviteCandidatesTabStore = new InviteCandidatesTabStore();
export const inviteCandidatesTabCtx = createContext(inviteCandidatesTabStore);

const EmailAssessmentInviteForm = observer(({ classes, history }) => {
    const [candidateTags, setCandidateTags] = useState([]);
    const [showNewTagsDialog, setShowNewTagsDialog] = useState(false);

    const { audition, loadEditScript } = useContext(assessmentSettingsCtx);
    const { loadAssessmentStats } = useContext(statsCtx);
    const { slug: scriptSlug, expired, valid } = audition;

    const {
        toggleResponseInviteDialog, errorMessage, errorLimit,
        showResponseInviteDialog, successfullySentData,
        inviteViaEmailForm, loading, hasInvalidPhoneNumbers, onSubmitValidate
    } = useContext(inviteCandidatesTabCtx);

    const goToAssessmentResults = () => {
        history.push(`/script/invite/${scriptSlug}/invite-candidates`);
        toggleResponseInviteDialog();
    };

    const onAllInvitationsSent = () => {
        loadAssessmentStats();
        loadEditScript();
    };

    const onSubmit = () => {
        onSubmitValidate({
            onAllInvitationsSent,
            assessment: audition,
            context: 'Invite page'
        });
    };

    const onCloseNewTagsDialog = (clearInviteForm = true) => {
        if (clearInviteForm) {
            inviteCandidatesTabStore.cleanForm();
        }
        setShowNewTagsDialog(false);
        setCandidateTags([]);
    };

    return (
        <div>
            <div className={classes.inviteWrapper}>
                <InviteForm
                    slug={scriptSlug}
                    assessment={audition}
                    context={inviteCandidatesTabCtx}
                    defaultRowCount={3}
                    onAllInvitationsSent={loadAssessmentStats}
                />
            </div>
            <div className={classes.inviteButtonsWrapper}>
                <ImportCSVForm
                    assessment={audition}
                    context={inviteCandidatesTabCtx}
                    setCandidateTags={setCandidateTags}
                    setShowNewTagsDialog={setShowNewTagsDialog}
                />
                <div className={classes.inviteByEmailActionRoot}>
                    <TestToMyselfButton className="u-mrg--rx2" audition={audition} slug={scriptSlug} />
                    {
                        errorMessage && (
                            <div className={classes.errorMessage}>
                                <i className="material-icons">
                                    info_outline
                                </i> {errorMessage}
                            </div>
                        )
                    }
                    <Button
                        className={classes.inviteButton}
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={inviteViaEmailForm.length === 0 || loading || hasInvalidPhoneNumbers || expired || !valid}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    className={classes.loader}
                                    size={25}
                                    thickness={3}
                                />
                            ) : (
                                <>
                                    Send {inviteViaEmailForm.length || ''}&nbsp;{checkPluralNew(inviteViaEmailForm.length, 'invitation')}
                                </>
                            )
                        }
                    </Button>
                </div>
            </div>
            <InviteResultsDialog
                errorLimit={errorLimit}
                open={showResponseInviteDialog}
                sentData={successfullySentData}
                onClose={toggleResponseInviteDialog}
            >
                <Button
                    color="primary"
                    className="u-txt--bold u-mrg--tx2"
                    onClick={goToAssessmentResults}
                >
                    Go to Candidates
                </Button>
            </InviteResultsDialog>
            <AddNewTagsDialog
                open={showNewTagsDialog}
                onClose={onCloseNewTagsDialog}
                tags={candidateTags}
            />
        </div>
    );
});

export default withStyles(styles)(withRouter(EmailAssessmentInviteForm));
