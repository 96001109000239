export default theme => ({
    loaderWrapper: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    wrapper: {
        display: 'flex',
        overflow: 'auto',
        backgroundColor: 'white',
        marginTop: 32,
        width: '100%',
        fontSize: 14,
        padding: '0 26px',
        minHeight: 110
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& *::-webkit-scrollbar': {
            display: 'none'
        },
        '& *': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
        }
    },
    statsWrapper: {
        maxWidth: 'calc(100% - 150px)'
    },
    content: {
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        position: 'relative'
    },
    label: {
        fontSize: 12,
        marginBottom: 10,
        fontWeight: 700,
        color: theme.palette.grey[900],
        textTransform: 'uppercase'
    },
    blueLabel: {
        fontSize: 12,
        marginBottom: 10,
        fontWeight: 700,
        color: theme.palette.blue[300],
        textTransform: 'uppercase'
    },
    ratingWrapper: {
        marginLeft: 'auto'
    },
    filterLabel: {
        marginRight: 6,
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    countLabel: {
        color: theme.palette.grey[600],
        fontSize: 12,
        cursor: 'pointer',
        textDecoration: 'underline',
        marginLeft: 4,
        fontWeight: 600
    },
    score: {
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: 14,
        marginLeft: 12,
        color: theme.palette.grey[900]
    },
    selectedScore: {
        color: theme.palette.primary.main
    },
    itemName: {
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    itemDate: {
        fontWeight: 600,
        color: theme.palette.grey[500]
    },
    ratingCandidateItem: {
        fontSize: 14,
        display: 'flex',
        paddingTop: 30,
        paddingBottom: 30,
        '&:not(:last-child)': {
            borderBottom: '2px solid #F3F3F3'
        }
    },
    downloadButton: {
        color: theme.palette.blue[500],
        fontWeight: 700
    },
    arrow: {
        cursor: 'pointer'
    },
    leftArrow: {
        transform: 'rotate(180deg)',
        marginRight: 32
    },
    rightArrow: {
        marginLeft: 32
    },
    disabledArrow: {
        opacity: 0.4
    }
});
